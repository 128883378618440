
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import JsPDF from "jspdf";
import autotable from "jspdf-autotable";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { event } from "vue-gtag";

interface Subject {
  name: string;
  coef: number;
  mark: number;
  min: string;
  max: string;
}

interface Module {
  name: string;
  coef: number;
  maxMoyenne: string;
  minMoyenne: string;
  moyenne: string;
  appreciation: string;
  subjects: Subject[];
  submodules: Module[];
}

interface Bulletin {
  available: boolean;
  moyenne: string;
  maxMoyenne: string;
  minMoyenne: string;
  fullName: string;
  students: number;
  classroomName: string;
  classroomLevel: number;
  modules: Module[];
  gender: string;
}

export default defineComponent({
  props: ["bulletin"],
  setup(props) {
    const { t } = useI18n();
    const loading = ref(false);

    const bulletin = props.bulletin as Bulletin;

    const store = useStore();

    let certifParams: { min: number; max: number; name: string }[] = [];

    ApiService.get("/certification")
      .then((res) => {
        certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });

    function getCertificationNameByMoyenne(moyenne) {
      for (const cert of certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert.name;
      return "";
    }

    const roundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
      const cornerRadius = {
        upperLeft: 0,
        upperRight: 0,
        lowerLeft: 0,
        lowerRight: 0,
      };
      if (typeof stroke == "undefined") {
        stroke = true;
      }
      if (typeof radius === "object") {
        for (const side in radius) {
          cornerRadius[side] = radius[side];
        }
      }

      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgb(70, 187, 239)";

      ctx.beginPath();
      ctx.moveTo(x + cornerRadius.upperLeft, y);
      ctx.lineTo(x + width - cornerRadius.upperRight, y);
      ctx.quadraticCurveTo(
        x + width,
        y,
        x + width,
        y + cornerRadius.upperRight
      );
      ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - cornerRadius.lowerRight,
        y + height
      );
      ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
      ctx.quadraticCurveTo(
        x,
        y + height,
        x,
        y + height - cornerRadius.lowerLeft
      );
      ctx.lineTo(x, y + cornerRadius.upperLeft);
      ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
      ctx.closePath();
      if (stroke) {
        ctx.stroke();
      }
      if (fill) {
        ctx.fill();
      }
    };

    const drawModuleHeader = (
      doc,
      ctx,
      startY,
      title,
      style = 1,
      isLangModule = false
    ) => {
      // module title

      let x = style == 0 ? 80 : 65;
      let y = 63 + startY;
      let width = style == 0 ? 110 : 125;
      doc.setFillColor(64, 187, 239);
      roundRect(ctx, x, y, width, 8, { upperRight: 8 }, true, true);
      doc.setFillColor(0xff, 0xff, 0xff);
      doc.setDrawColor(0xff, 0xff, 0xff);
      doc.rect(x, y + 20, width + 10, 10, "FD");

      // module header shapes
      x = 158;
      y = 72.6 + startY;
      width = 45;
      doc.setFillColor(64, 187, 239);
      roundRect(ctx, x, y, width, 7.5, { upperRight: 7.5 }, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      x = 148;
      y = 72.6 + startY;
      width = style == 0 ? 26 : 18;
      if (isLangModule) {
        width -= 4;
        x += 5;
      }
      doc.setFillColor(224, 237, 243);
      //العدد / 20
      roundRect(ctx, x, y, width, 7.4, { upperRight: 7.4 }, true, true);
      // doc.setFillColor(0xff, 0xff, 0xff);
      // doc.setDrawColor(0xff, 0xff, 0xff);
      // doc.rect(x, y + 9.9, width + 10, 10, "FD");

      doc.setLineWidth(0.4);
      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);
      // معدل
      if (isLangModule) {
        doc.rect(137, 72.3 + startY, 15, 8, "FD");
      }

      //معدل المجال;
      if (style == 1) {
        x = 0;
        if (isLangModule) {
          x = 10;
        }
        doc.rect(134 - x, 72.3 + startY, 15, 8, "FD");
      }
      //توصيّات المدّرس
      x = 0;
      width = style == 0 ? 46 : 38;
      if (isLangModule) {
        width -= 2;
        x = 8;
      }
      doc.rect(style == 0 ? 111 : 96 - x, 72.3 + startY, width, 8, "FD");

      // max / min
      width = 15;
      x = 0;
      if (isLangModule) {
        width = 11;
        x = 4;
      }
      doc.rect(style == 0 ? 80 : 65, 72.3 + startY, width, 8, "FD");
      doc.rect((style == 0 ? 95 : 80) - x, 72.3 + startY, width, 8, "FD");

      doc.line(155, 80.3 + startY, 202.5, 80.3 + startY);

      // header module texts
      if (/[a-zA-Z]/.test(title)) doc.setFont("Amiri", "Bold");
      else doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = title;
      doc.setTextColor(0xff, 0xff, 0xff);
      width = doc.getTextWidth(text);
      doc.text(text, 138 - width / 2 - (style == 0 ? 0 : 15), 69 + startY);

      doc.setFont("Amiri", "Bold");
      text = "المادة";
      width = doc.getTextWidth(text);
      doc.text(text, 179, 78 + startY);

      text = "العدد / 20";
      doc.setFontSize(9);
      x = style == 0 ? 158 : 151;
      if (isLangModule) {
        doc.setFontSize(7);
        x += 4;
      }

      doc.setTextColor(62, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, x, 78 + startY);

      if (isLangModule) {
        text = "معدل";
        doc.setFontSize(9);
        doc.setTextColor(64, 187, 239);
        width = doc.getTextWidth(text);
        doc.text(text, 146, 77.5 + startY, "center");
      }

      if (style == 1) {
        text = "معدل\nالمجال";
        x = 0;
        if (isLangModule) x = 11;
        doc.setFontSize(9);
        doc.setTextColor(64, 187, 239);
        width = doc.getTextWidth(text);
        doc.text(text, 142 - x, 75.2 + startY, "center");
      }

      text = "توصيّات المدّرس";
      x = 0;
      if (isLangModule) x = 8;
      doc.setFontSize(11);
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 125 : 104 - x, 77.5 + startY);

      doc.setFontSize(8);
      x = 0;
      if (isLangModule) {
        doc.setFontSize(7);
        x = 5.5;
      }
      text = "أعلى\nعدد بالقسم";
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 103 : 88 - x, 75 + startY, "center");

      if (isLangModule) x = 1;

      text = "أدنى\nعدد بالقسم";
      doc.setTextColor(64, 187, 239);
      width = doc.getTextWidth(text);
      doc.text(text, style == 0 ? 87 : 72 - x, 75 + startY, "center");
    };

    const drawModuleFooter = (doc, ctx, startY, avg) => {
      let x = 158;
      let y = startY;
      let width = 35.5;
      doc.setFillColor(16, 169, 229);
      doc.setDrawColor(16, 169, 229);
      doc.rect(x, y, width + 10, 8.5, "FD");

      x = 111;
      y = startY + 0.4;
      width = 45.5;
      doc.setFillColor(255, 255, 255);

      roundRect(ctx, x, y, width, 7.8, { lowerLeft: 7.8 }, true, true);

      // footer text (moyenne)
      doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(13);
      let text = "معدل المجال";
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 169, startY + 5.5);

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(14);
      text = String(avg);
      doc.setTextColor(67, 62, 63);
      width = doc.getTextWidth(text);
      doc.text(text, 134.75 - width / 2, startY + 5.5);
    };

    const drawTrimesterMoyenneAndCards = (
      doc,
      ctx,
      startY,
      moy,
      max,
      min,
      style = 1
    ) => {
      const value = style == 1 ? 5 : 0;

      doc.setFillColor(46, 142, 178);
      doc.setDrawColor(46, 142, 178);
      roundRect(
        ctx,
        45 - value * 2,
        82.2 + startY,
        33.5 - value,
        8.2,
        { upperRight: 7.8 },
        true,
        false
      );

      doc.setFont("Noto Sans Arabic", "Bold");
      doc.setFontSize(11);
      let text = "معدل الثلاثي";
      doc.setTextColor(0xff, 0xff, 0xff);
      doc.text(text, 51 - (value * 2 + value / 2), 87.5 + startY);

      doc.setLineWidth(0.2);
      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);

      roundRect(
        ctx,
        6,
        82.2 + startY,
        38 - value * 2,
        7.8,
        { upperRight: 7.8 },
        true,
        true
      );
      roundRect(
        ctx,
        6,
        82.2 + startY,
        19 - value,
        7.8,
        { upperRight: 7.8 },
        true,
        true
      );

      doc.setFont("Amiri", "Bold");
      doc.setFontSize(7);
      doc.setTextColor(64, 187, 239);
      doc.text(
        "أعلى\nمعدل بالقسم",
        38 - (value + value / 2),
        85.5 + startY,
        "right"
      );
      doc.text("أدنى\nمعدل بالقسم", 19.2 - value / 2, 85.5 + startY, "right");

      // moyenne rect
      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);
      doc.setLineWidth(0.15);
      doc.rect(45 - value * 2, 91.5 + startY, 33.5 - value, 16.4, "FD");

      doc.setFillColor(225, 225, 226);
      doc.rect(6, 91.5 + startY, 19 - value, 16.4, "FD");
      doc.rect(25 - value, 91.5 + startY, 19 - value, 16.4, "FD");

      // moyenne text
      doc.setTextColor(67, 62, 63);
      doc.setFontSize(16);
      text = String(moy);
      let width = doc.getTextWidth(text);
      doc.text(text, 63 - width / 2 - (value * 2 + value / 2), 100.5 + startY);

      doc.setFontSize(13);
      text = String(max);
      width = doc.getTextWidth(text);
      doc.text(text, 34.75 - width / 2 - (value + value / 2), 100.5 + startY);

      text = String(min);
      width = doc.getTextWidth(text);
      doc.text(text, 15.25 - width / 2 - value / 2, 100.5 + startY);

      doc.setFillColor(224, 237, 243);
      doc.setDrawColor(64, 187, 239);

      doc.rect(6, 115 + startY, 72.5 - value * 3, 30);
      doc.rect(8, 111 + startY, 67.5 - value * 3, 8, "FD");

      doc.rect(6, 153 + startY, 72.5 - value * 3, 30);
      doc.rect(40.75 - value * 3, 149 + startY, 36.75, 8, "FD");

      doc.rect(6, 190 + startY, 72.5 - value * 3, 55);
      doc.rect(40.75 - value * 3, 186 + startY, 36.75, 8, "FD");

      doc.rect(6, 252 + startY, 72.5 - value * 3, 30);
      doc.rect(40.75 - value * 3, 248 + startY, 36.75, 8, "FD");

      doc.setFontSize(11 - value / 2);
      doc.setTextColor(64, 187, 239);
      text = "ملاحظات المدّرس)ة( حول السّلوك و المواظبة";
      doc.text(text, 12, 116 + startY);

      doc.setFontSize(13);
      text = "الشّهادة";
      doc.text(text, 54 - value * 3, 154 + startY);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(17);

      doc.text(
        getCertificationNameByMoyenne(moy),
        35 - value * 3,
        170 + startY
      );
      doc.setFontSize(13);

      doc.setTextColor(64, 187, 239);

      text = "مدير)ة( المدرسة";
      doc.text(text, 45 - value * 3, 191 + startY);

      doc.setTextColor(0, 0, 0);
      doc.text(
        store.getters.serverConfigUrl.building.headMaster,
        30 - value * 3,
        207 + startY
      );

      doc.setTextColor(64, 187, 239);
      doc.setFontSize(16);

      text = "إمضاء الولي";
      doc.text(text, 48 - value * 3, 253 + startY);
    };

    const generatePDF = () => {
      event("Imp bulletin pedagogique", {
        event_category: "Impression PDF",
        event_label: "Bulletin pedagogique",
        value: 1,
      });

      //generate bulletin pdf
      loading.value = true;
      const style = bulletin.classroomLevel > 2 ? 1 : 0;

      const doc = new JsPDF();
      const ctx = doc.context2d;
      const pdf_width = doc.internal.pageSize.width;
      let width = 0;
      let width2 = 0;

      // background header
      const img = new Image();
      img.src = "./media/misc/bg1.png";
      doc.addImage(img, "png", 0, 0, pdf_width, 40 - (style == 1 ? 12 : 0));

      // Header texts
      doc.setFont("PT Bold Heading", "Bold");
      doc.setFontSize(13);
      let text = "المندوبيّة الجهويّة للتربيّة";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 15, 12);
      text = store.getters.serverConfigUrl.delegeRegional
        ? "بـ" + store.getters.serverConfigUrl.delegeRegional
        : ".....................................بـ";
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 15, 22);

      doc.setFont("Amiri", "normal");

      doc.setTextColor(67, 62, 63);
      doc.setFontSize(12);
      text = store.getters.serverConfigUrl.building.ArabicName
        ? " المدرسة الابتدائيّة " +
          store.getters.serverConfigUrl.building.ArabicName
        : "................................................... : المدرسة الابتدائيّة";
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width - 15, 12);
      text = ` ${store.getters.currentYear.name}  : السنة الدراسيّة`;
      width = doc.getTextWidth(text);
      doc.text(text, 105 - width - 15, 20);

      // ending header line
      doc.setDrawColor(52, 172, 215);
      doc.setLineWidth(0.8);
      doc.line(
        0,
        40 - (style == 1 ? 12 : 0),
        pdf_width,
        40 - (style == 1 ? 12 : 0)
      );

      // trimester rounded rect title
      doc.setDrawColor(52, 172, 215);
      doc.setLineWidth(0.4);
      doc.setFillColor(255, 255, 255);
      doc.roundedRect(
        pdf_width / 2 - 25,
        34 - (style == 1 ? 12 : 0),
        50,
        12,
        4,
        4,
        "FD"
      );

      // trimester text
      doc.setFontSize(16);
      doc.setFont("Amiri", "normal");
      doc.setTextColor(52, 172, 215);
      let title = "";
      switch (String(store.getters.currentTrimester)) {
        case "1":
          title = "الثلاثي الأول";
          break;
        case "2":
          title = "الثلاثي الثاني";
          break;
        case "3":
          title = "الثلاثي الثالث";
          break;
      }
      doc.setFont("Noto Sans Arabic", "Bold");
      width = doc.getTextWidth(title);
      doc.text(title, pdf_width / 2 - width / 2, 41.2 - (style == 1 ? 12 : 0));

      const className = bulletin.classroomName;

      let studentLabel =
        bulletin.gender === "male" ? " : التلميذ" : " : التلميذة";

      // student name
      doc.setFont("Amiri", "normal");
      doc.setFontSize(12);
      doc.setTextColor(99, 87, 91);
      text = studentLabel;
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 12, 53 - (style == 1 ? 12.5 : 0));

      text = bulletin.fullName;
      width2 = doc.getTextWidth(text);
      doc.text(
        text,
        pdf_width - width - width2 - 12,
        53 - (style == 1 ? 12.5 : 0)
      );

      // student classroom
      text = " : القسم";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 100, 53 - (style == 1 ? 12.5 : 0));
      text = className;
      width2 = doc.getTextWidth(text);
      doc.text(
        text,
        pdf_width - width - width2 - 100,
        53 - (style == 1 ? 12.5 : 0)
      );

      // number of students in classroom
      text = " : عدد التلاميذ المرسّمين";
      doc.setTextColor(99, 87, 91);
      width = doc.getTextWidth(text);
      doc.text(text, pdf_width - width - 156, 53 - (style == 1 ? 12.5 : 0));
      text = String(bulletin.students);
      width2 = doc.getTextWidth(text);
      doc.text(
        text,
        pdf_width - width - width2 - 156,
        53 - (style == 1 ? 12.5 : 0)
      );

      const cellToDelete = {};
      let topMargin = -4 - (style == 1 ? 13 : 0);

      let tableSize = 9;
      switch (Number(bulletin.classroomLevel)) {
        case 1:
          tableSize = 12;
          break;
        case 2:
          tableSize = 12;
          break;
        case 3:
          tableSize = 11;
          break;
        case 4:
          tableSize = 10.5;
          break;
        default:
          break;
      }

      bulletin.modules.forEach((m) => {
        let arr: any[] = [];
        let array: any[] = [];

        drawModuleHeader(doc, ctx, topMargin, m.name, style, !!m.submodules);
        topMargin += 17.2;

        m.subjects.forEach((c) => {
          let subFormattedName = c.name;
          if (c.name.length > 20 && c.name.indexOf(" و ") != -1) {
            let indexOfFirst = c.name.indexOf(" و ");
            subFormattedName =
              c.name.slice(0, indexOfFirst) + "\n" + c.name.slice(indexOfFirst);
          }
          arr[m.submodules ? 7 : 6] = {
            content: subFormattedName,
            styles: {
              halign: "right",
              fillColor: "#40bbef",
              fontSize: tableSize,
              textColor: "#ffffff",
              lineColor: [229, 229, 229],
            },
          };

          arr[m.submodules ? 6 : 5] = {
            content: c.mark,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };

          arr[4] = cellToDelete;
          if (style == 1) arr[3] = cellToDelete;
          arr[2] = cellToDelete;

          //best mark
          arr[1] = {
            content: c.max,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: m.submodules ? 9 : 10,
              lineColor: [52, 172, 215],
            },
          };

          //lowest mark
          arr[0] = {
            content: c.min,
            styles: {
              halign: "center",
              valign: "center",
              fontSize: m.submodules ? 9 : 10,
              lineColor: [52, 172, 215],
            },
          };

          array.push(arr);
          arr = [];
        });

        array[0][2] = {
          content: "",
          rowSpan: array.length,
          styles: {
            lineColor: [52, 172, 215],
          },
        };
        if (!m.submodules) {
          array[0][style == 1 ? 3 : 4] = {
            content: m.appreciation.trim(),
            rowSpan: array.length,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: m.appreciation.length > 15 ? 10 : 12,
              lineColor: [52, 172, 215],
            },
          };
        } else {
          array[0][3] = {
            content: m.submodules[0].appreciation.trim(),
            rowSpan: 3,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
          array[3][3] = {
            content: m.submodules[1].appreciation.trim(),
            rowSpan: 2,
            styles: {
              halign: "center",
              valign: "top",
              fontSize: 10,
              lineColor: [52, 172, 215],
            },
          };
        }

        if (style == 1)
          array[0][4] = {
            content: m.moyenne,
            rowSpan: array.length,
            styles: {
              halign: "center",
              fontSize: 12,
              valign: style ? "center" : "top",
              lineColor: [52, 172, 215],
            },
          };

        if (m.submodules) {
          array[0][5] = {
            content: m.submodules[0].moyenne,
            rowSpan: 3,
            styles: {
              halign: "center",
              fontSize: 12,
              valign: "center",
              lineColor: [52, 172, 215],
            },
          };
          array[3][5] = {
            content: m.submodules[1].moyenne,
            rowSpan: 2,
            styles: {
              halign: "center",
              fontSize: 12,
              valign: "center",
              lineColor: [52, 172, 215],
            },
          };
        }
        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        autotable(doc, {
          startY: 64 + topMargin,
          theme: "grid",
          styles: {
            font: "Amiri",
            halign: "center",
            fontStyle: "Bold",
          },
          cellPadding: 0,
          body: array,
          margin: { left: style == 1 ? 65 : 80, bottom: 0 },
          didDrawPage: function (data) {
            // Reseting top margin. The change will be reflected only after print the first page.
            data.settings.margin.top = 10;
          },
          columnStyles:
            style == 1
              ? m.submodules
                ? {
                    0: { cellWidth: 11 },
                    1: { cellWidth: 11 },
                    2: { cellWidth: 1 },
                    3: { cellWidth: 36 },
                    4: { cellWidth: 15 },
                    5: { cellWidth: 14 },
                    6: { cellWidth: 14 },
                    7: { cellWidth: 37 },
                  }
                : {
                    0: { cellWidth: 15 },
                    1: { cellWidth: 15 },
                    2: { cellWidth: 1 },
                    3: { cellWidth: 38 },
                    4: { cellWidth: 15 },
                    5: { cellWidth: 17 },
                    6: { cellWidth: 38 },
                  }
              : {
                  0: { cellWidth: 15 },
                  1: { cellWidth: 15 },
                  2: { cellWidth: 1 },
                  3: { cellWidth: 46 },
                  4: { cellWidth: 17 },
                  5: { cellWidth: 30 },
                },
        } as any);

        if (style == 0)
          drawModuleFooter(
            doc,
            ctx,
            (doc as any).lastAutoTable.finalY + 1,
            m.moyenne
          );

        topMargin =
          (doc as any).lastAutoTable.finalY - 61.4 + (style == 0 ? 11 : 0);
      });

      let cardStartY = 0;
      switch (Number(bulletin.classroomLevel)) {
        case 1:
          cardStartY = -15;
          break;
        case 2:
          cardStartY = -15;
          break;
        case 3:
          cardStartY = -22;
          break;
        case 4:
          cardStartY = -22;
          break;
        case 5:
          cardStartY = -22;
          break;
        case 6:
          cardStartY = -22;
          break;
        default:
          break;
      }

      drawTrimesterMoyenneAndCards(
        doc,
        ctx,
        cardStartY,
        bulletin.moyenne,
        bulletin.maxMoyenne,
        bulletin.minMoyenne,
        style
      );

      loading.value = false;
      doc.save("Bulletin " + bulletin.fullName + ".pdf");
    };

    return { t, generatePDF, loading };
  },
  methods: {},
});
